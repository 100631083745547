import React from "react";
import Layout from "../../Layout";
import "./style.scss";
import data from "./data";
import SEO from "../../seo";

export default function ATL() {
  return (
    <Layout>
      <SEO
        title="ATAL TINKERING LAB (ATL) | Chinmaya Vidyalaya"
        description=""
        img="https://chinmayavvdelhi.ac.in/images/Blackele/Chinmaya_link_preview.jpg"
        keywords=""
      />
      <div className="atl">
        <h1 className="heading">ATAL TINKERING LAB (ATL)</h1>

        <p className="atl__text">
          ATAL TINKERING LAB (ATL) is an approach of Central Government of India
          to create an environment of scientific temperament, innovation and
          creativity among Indian students. ATL teaches students the essential
          21st-century skills which will help them in developing their
          professional and personal skills.
          <br />
          <br />
          In this world of advancement in Science and Technology there is an
          urgent need to provide technical skills to the students along with
          theoretical knowledge. ATL is the best platform for the students to
          learn Engineering skills at the school level starting from Class VI.
          Students get an opportunity for hands on with electronics equipment
          including the sensors and developing the skill of circuit making,
          programming, 3D printing, Drone making and even the skills of handling
          tools like soldering machine, drill machine, glue gun, hacksaw,
          pliers, screw drivers etc.
          <br />
          <br />
          ATL students also get an opportunity to learn software skills like
          developing WebApp, Android App & Games and are also being trained to
          do 3D designing on TinkerCad and hence operate 3D printer. Every year
          our students participate in the competitions and make innovative
          projects for the same. Many students and teams have brought laurels by
          winning prizes in these competitions.
        </p>

        <div className="atl__images">
          <div className="row">
            {data.map((item) => (
              <div className="col col-12 col-md-6 col-xl-4">
                <img className="atl__images__img" src={item}></img>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Layout>
  );
}
