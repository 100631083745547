const data = [
  "https://chinmayavvdelhi.ac.in/assets/images/atl/pic1.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/atl/pic2.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/atl/pic3.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/atl/pic4.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/atl/pic5.jpg",
  "https://chinmayavvdelhi.ac.in/assets/images/atl/pic6.jpg",
];

export default data;
